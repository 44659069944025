






















import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsStepMediumGoalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-step-medium-goal-view-model';

@Component({ name: 'FlagshipGoalsStepMediumGoal' })
export default class FlagshipGoalsStepMediumGoal extends Vue {
  step_medium_goal_model = Vue.observable(new FlagshipGoalsStepMediumGoalViewModel());
}
